/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/tooltipster@4.2.6/dist/css/tooltipster.bundle.min.css
 * - /npm/tooltipster@4.2.6/dist/css/plugins/tooltipster/sideTip/themes/tooltipster-sideTip-shadow.min.css
 * - /npm/slim-select@1.25.0/dist/slimselect.min.css
 * - /npm/animate.css@3.7.0/animate.min.css
 * - /npm/flatpickr@4.5.2/dist/flatpickr.min.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
